@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: 'Poppins';
}

@keyframes drop-in {
    0% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .animate-drop-in {
    animation: drop-in 0.8s ease-out forwards;
  }
  
.transition-field {
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .drop-in {
    opacity: 1 !important;
    transform: translateY(0px) !important;
  }

@keyframes moveAround {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(10px, -10px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  75% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.moving-image {
  animation: moveAround 5s ease-in-out infinite;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.modal-content {
  animation: pop-in 0.4s ease forwards; /* Animation for modal */
}


.carousel-container {
  width: 90%;
  max-width: 1000px;
}

.carousel-panel {
  overflow: hidden;
  border-radius: 8px;
}

.carousel-strip {
  width: 200px; /* Adjust thickness */
  height: 500px;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s ease;
  overflow: hidden;
  margin: 0 10px; /* Adds space between each strip */
}

.carousel-strip img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-strip:hover {
  transform: scale(1.05);
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Darkened background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Image */
.modal-image {
  max-width: 90vw;  /* Make sure the image doesn't exceed the viewport width */
  max-height: 80vh; /* Make sure the image doesn't exceed the viewport height */
  object-fit: contain; /* Scale down while preserving the aspect ratio */
  display: block;
  margin: 0 auto;  /* Center the image horizontally */
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  z-index: 1010;
}

.close-btn:hover {
  color: red;
}
